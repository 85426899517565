<template>
<div class="customer-detail-info">
  <el-row>
    <el-col :span="8">
      <span class="label">姓名:</span>
      <span>{{ viewData.userName ||' ' }}</span>
    </el-col>
    <el-col :span="8">
      <span class="label">国籍:</span>
      <span>{{ viewData.nationName}}</span>
    </el-col>
    <el-col :span="8">
      <span class="label">性别:</span>
      <span>{{ viewData.gender == 2 ? '女': '男'}}</span>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="8">
      <span class="label">电话号码:</span>
      <span>{{ viewData.phone}}</span>
    </el-col>
    <el-col :span="8">
      <span class="label">邮箱:</span>
      <span>{{ viewData.email }}</span>
    </el-col>
    <el-col :span="8">
      <span class="label">生日:</span>
      <span>{{viewData.birthday }}</span>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="12">
      <span class="label">证件号码:</span>
      <span>{{viewData.certId }}</span>
    </el-col>
    <el-col :span="12">
      <span class="label">居住国:</span>
      <span>{{viewData.nationName }}</span>
    </el-col>

  </el-row>
  <el-row>
    <el-col>
      <span class="label">详细地址:</span>
      <span>{{viewData.homeProvince }}-{{viewData.homeCity }}-{{viewData.homeStreet }}-{{viewData.homeAddress }}</span>
    </el-col>
  </el-row>
  <el-row>
    <el-col>
      <span class="label">身份证照片:</span>
      <el-image
          v-if="viewData.certValidatedImgFront"
          style="width: 170px;height: 120px"
          :src="viewData.certValidatedImgFront"
          :preview-src-list="[viewData.certValidatedImgFront,viewData.certValidatedImgBack]"
      />
      <el-image
          v-if="viewData.certValidatedImgBack"
          style="width: 170px;height: 120px;margin-left: 10px"
          :src="viewData.certValidatedImgBack"
          :preview-src-list="[viewData.certValidatedImgFront,viewData.certValidatedImgBack]"
      />
    </el-col>
  </el-row>
  <el-row
      v-if="viewData.handleCertImg"
  >
    <el-col>
      <span class="label">手持证件照:</span>
      <el-image
          style="width: 170px;height: 120px"
          :src="viewData.handleCertImg"
          :preview-src-list="[viewData.handleCertImg]"
      />
    </el-col>
  </el-row>
  <el-row>
    <el-col>
      <span class="label">地址证明:</span>
      <el-image
          v-if="viewData.addressImg"
          style="width: 170px;height: 120px"
          :src="viewData.addressImg"
          :preview-src-list="[viewData.addressImg]"
      />
    </el-col>
  </el-row>
  <div class="border-box"></div>
  <el-row>
    <el-col :span="12">
      <span class="label">就业状况:</span>
      <span>{{workStatus}}</span>
    </el-col>
    <el-col :span="12">
      <span class="label">行业:</span>
      <span>{{industry}}</span>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="12">
      <span class="label">流动资产:</span>
      <span>{{ runAssets}}</span>
    </el-col>
    <el-col :span="12">
      <span class="label">准备用来做投资的资金来源:</span>
      <span>{{ fundsSource}}</span>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="12">
      <span class="label">投资目标:</span>
      <span>{{ invTargetIs}}</span>
    </el-col>
    <el-col :span="12">
      <span class="label">是否有CFDs的交易历史:</span>
      <span>{{ viewData.hadFx == 1 ? '是': '否'}}</span>
    </el-col>
  </el-row>
</div>
</template>

<script>
import { getDictListByType, initData } from '@/api/data'
import checkPermission from '@/utils/permission'

export default {
  name: 'customerDetailInfo',
  props: {
    // 传入custId
    custId: {
      // type: Number,
      default: 0,
    }
  },
  data() {
    return {
      // 投资情况
      workList: [],
      industryList: [],
      incomeList: [],
      assetsList: [],
      fundsSourceList: [],
      invTargetList:[],

      invTargetIs: '',
      fundsSource: '',

      viewData: {}
    }
  },
  created(){
    if(this.custId){
      this.getImages();
    }
    this.getDictListByType('workList');
    this.getDictListByType('industryList');
    this.getDictListByType('incomeList');
    this.getDictListByType('assetsList');

    this.getFundsSource();
    this.getInvTargetIs();
  },
  watch:{
    custId: {
      handler(newVal) {
        this.getFundsSource();
        this.getInvTargetIs();
        if(newVal){
          this.getImages();
        }
      },
      deep: true
    }
  },
  computed: {
    runAssets() {
      let  text = '-';
      for (let i = 0; i < this.assetsList.length; i++) {
        if(this.assetsList[i].value == this.viewData.curAsset){
          text = this.assetsList[i].label;
          break;
        }
      }
      return text;
    },
    industry() {
      let  text = '-';
      for (let i = 0; i < this.industryList.length; i++) {
        if(this.industryList[i].value == this.viewData.industry){
          text = this.industryList[i].label;
          break;
        }
      }
      return text;
    },
    workStatus(){
      let  text = '-';
      for (let i = 0; i < this.workList.length; i++) {
        if(this.workList[i].value == this.viewData.empStatus){
          text = this.workList[i].label;
          break;
        }
      }
      return text;
    },
  },
  methods: {
    getImages(){
      const url = 'crm/tCustInfo';
      const params = {
        id:this.custId,
        page: 0,
        size: 10,
        flag:'false'}
      initData(url, params).then(res => {
        this.viewData = res.content[0];
      }).catch(err => {
      })
    },
    getDictListByType(type) {
      let parmas = {
        type: type
      };
      getDictListByType(parmas).then((res) => {
        if(res[0].type == 'invTargetList') {
          this.invTargetList = res;
          let totalStrArr = []
          let strArr =  null;
          if(this.viewData.invTarget){
            strArr = this.viewData.invTarget.split(',');
          }
          if(strArr && strArr.length){
            for (let i = 0; i < this.invTargetList.length; i++) {
              for (let j = 0; j < strArr.length; j++) {
                if(this.invTargetList[i].value == strArr[j]){
                  totalStrArr.push(this.invTargetList[i].label);
                }
              }
            }
          }
          this.invTargetIs = totalStrArr.join(',');
        }
        if (res[0].type == 'workList') {
          this.workList = res;
        } else if (res[0].type == 'industryList') {
          this.industryList = res;
        } else if (res[0].type == 'incomeList') {
          this.incomeList = res;
        } else if (res[0].type == 'assetsList') {
          this.assetsList = res;
        } else if (res[0].type == 'fundsSourceList') {
          this.fundsSourceList = res;
          let totalStrArr = [];
          let strArr =  null;
          if(this.viewData.fundsSource){
            strArr = this.viewData.fundsSource.split(',');
          }
          if(strArr && strArr.length){
            for (let i = 0; i < this.fundsSourceList.length; i++) {
              for (let j = 0; j < strArr.length; j++) {
                if(this.fundsSourceList[i].value == strArr[j]){
                  totalStrArr.push(this.fundsSourceList[i].label);
                }
              }
            }
          }
          this.fundsSource = totalStrArr.join(',');
        }
      });
    },
    getFundsSource() {

      getDictListByType({type: 'fundsSourceList'}).then((res) => {
        if (res[0].type == 'fundsSourceList') {
          this.fundsSourceList = res;
        }
        let totalStrArr = [];
        let strArr =  null;
        if(this.viewData.fundsSource){
          strArr = this.viewData.fundsSource.split(',');
        }
        if(strArr && strArr.length){
          for (let i = 0; i < this.fundsSourceList.length; i++) {
            for (let j = 0; j < strArr.length; j++) {
              if(this.fundsSourceList[i].value == strArr[j]){
                totalStrArr.push(this.fundsSourceList[i].label);
              }
            }
          }
        }
        this.fundsSource = totalStrArr.join(',');
      })
    },
    getInvTargetIs() {

      getDictListByType({type: 'invTargetList'}).then((res) => {
        if(res[0].type == 'invTargetList') {
          this.invTargetList = res;
        }
        let totalStrArr = []
        let strArr =  null;
        if(this.viewData.invTarget){
          strArr = this.viewData.invTarget.split(',');
        }
        if(strArr && strArr.length){
          for (let i = 0; i < this.invTargetList.length; i++) {
            for (let j = 0; j < strArr.length; j++) {
              if(this.invTargetList[i].value == strArr[j]){
                totalStrArr.push(this.invTargetList[i].label);
              }
            }
          }
        }
        this.invTargetIs = totalStrArr.join(',');
      })
    },

  }
}
</script>

<style lang="less" scoped>
.customer-detail-info {
  .label {
    padding: 10px 10px;
  }
  .reject-text,
  .label {
    font-size: 18px;
  }
  .textarea-el,
  .select-el{
    width: 400px;
  }
  .textarea-outer {
    margin-top: 10px;
  }
  .border-box {
    height: 1px;
    background-color: #dddddd;
  }
  /deep/ .el-row {
    margin-bottom: 20px;
    padding: 10px 0;
  }

   /deep/ .el-col {
    border-radius: 4px;
  }
}
</style>

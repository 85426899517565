import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'crm/tCustInfo',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'crm/tCustInfo/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'crm/tCustInfo',
    method: 'put',
    data
  })
}


export function audit(data) {
  return request({
    url: '/crm/tCustInfo/applyAudit',
    method: 'get',
    params: data
  })
}


export function auditImg(data) {
  return request({
    url: '/crm/tCustInfo/auditIdAndAddsAndBankStatus',
    method: 'get',
    params: data
  })
}


export function openMT4Acc(data) {
  return request({
    url: '/crm/mtacc/openMT4Acc',
    method: 'get',
    params: data
  })
}

// merId
// managerId: 客户经理Id
// custId 客户id
// oprType
export function alterBelongInfo(data) {
  return request({
    url: '/crm/alterBelongInfo',
    method: 'get',
    params: data
  })
}
export function getManagerList(query) {
  return request({
    url: 'crm/tManager',
    method: 'get',
    params: {
      size: 500,
      fullname: query
    }
  })
}

// 直客级别下列列表
export function getGuestList(data){
  return request({
    url: '/crm/custLink',
    method: 'get',
    params: data
  })
}